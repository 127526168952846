import React from "react";
import { hydrateRoot } from 'react-dom/client';
import { Loader } from "./shared/loader";
import "./style.css";
import { Toaster } from "react-hot-toast";

const pages: any = {
    "Home": React.lazy(() => import("./pages/home")),
    "Login": React.lazy(() => import("./pages/login")),
    "Signup": React.lazy(() => import("./pages/signup")),
    "Dashboard": React.lazy(() => import("./pages/automations")),
    "Settings": React.lazy(() => import("./pages/settings")),
    "AcceptInvite": React.lazy(() => import("./pages/accept")),
    "ResetPassword": React.lazy(() => import("./pages/reset")),
    "ForgotPassword": React.lazy(() => import("./pages/forgot")),
    "Blogs": React.lazy(() => import("./pages/blogs")),
    "BlogContent": React.lazy(() => import("./pages/blog")),
    "Automations": React.lazy(() => import("./pages/automations")),
    "Results": React.lazy(() => import("./pages/results")),
    "Automation": React.lazy(() => import("./pages/automation")),
    "Result": React.lazy(()=>import("./pages/result")),
    "VerifyUser": React.lazy(()=>import("./pages/verify"))
};

window.onload = async () => {
    const root = document.getElementById("root");
    if (root) {
        const componentName = root.dataset.component;
        let componentProps = {};

        if (root.dataset.props) {
            componentProps = JSON.parse(root.dataset.props);
        }

        if (componentName) {
            const Component = pages[componentName];

            if (Component) {
                hydrateRoot(root,
                    <React.Suspense fallback={<Loader />}>
                        <Component {...componentProps} />
                        <Toaster position="bottom-right" reverseOrder={false} />
                    </React.Suspense>
                )
            }
        }
    }
}
